<template>
	<div>
		<basic-modal
			v-for="modal in modals"
			:key="modal.id"
			:dialog="modal.dialog"
			@close="handleClose(modal)"
			:close-on-escape="modal.closeOnEscape"
			:fullscreen="modal.fullScreen"
			overlay-color="modal_overlay"
		>
			<component
				:ref="modal.id"
				:is="modal.component"
				v-bind="modal.binding"
				@result="onResult(modal, $event)"
				@error="onError(modal, $event)"
			></component>
		</basic-modal>
	</div>
</template>

<script>
	import Deferred from '@/modules/utils/Deferred';
	import { v4 as uuidv4 } from 'uuid';
	import BasicModal from '@/components/BasicModal';
  import ConfirmModal from '@/components/ConfirmModal';
  import InvoiceDetails from '@/modules/invoices/InvoiceDetails';
  import PaymentDetailsModal from '@/modules/invoices/InvoiceRenderer/PaymentDetailsModal';
  import ProposalDetails from '@/modules/proposal/ProposalDetails';
  import DeliverableDetail from '@/modules/projects/deliverable/DeliverableDetail';
  import MeetingEmbed from '@/modules/meetings/MeetingEmbed';
  import MeetingDetail from '@/modules/meetings/MeetingDetail';
  import NewAchAccount from '@/modules/invoices/NewAchAccount';
  import ManualAchAccount from '@/modules/invoices/ManualAchAccount';
  import VerifyAchAccount from '@/modules/invoices/VerifyAchAccount';
  import SubscriptionPayments from '@/modules/subscriptions/SubscriptionPayments';
  import ProjectSelector from '@/modules/projects/ProjectSelector';
  import UpdatePassword from '@/modules/auth/UpdatePassword';

	export default {
		name: 'GlobalModalController',

		props: [],

		components: {
			BasicModal,
      ConfirmModal,
      InvoiceDetails,
      PaymentDetailsModal,
      ProposalDetails,
      DeliverableDetail,
      MeetingEmbed,
      MeetingDetail,
      NewAchAccount,
      ManualAchAccount,
      VerifyAchAccount,
      SubscriptionPayments,
      ProjectSelector,
      UpdatePassword
		},

		data: function () {
			return {
				ignoreBackEvent: false,
				modals: [],
			};
		},

		mounted() {
			this.$store.state.globalModalController = this;
			window.onpopstate = this.handleHistoryPop
		},

		beforeDestroy() {
			this.$store.state.globalModalController = null;
		},

		methods: {
			handleHistoryPop: function(){
				if(this.ignoreBackEvent){
					this.ignoreBackEvent = false;  //reset flag
					return;
				}

				let modal = this.modals.pop();
				if(modal){
					this.removeModal(modal,false);
				}
			},

			openModal(component, binding, closeOnEscape = true, fullScreen = false) {
				let dfd = new Deferred();
				let modal = {
					id: uuidv4(),
					component: component.name,
					binding: binding,
					dialog: true,
					closeOnEscape: closeOnEscape,
					fullScreen: fullScreen,
					dfd: dfd,
				};

				setTimeout(() => {
					window.history.pushState({id: modal.id}, document.title, location.href + '#' + modal.id);
				},250);

				this.modals.push(modal);
				return dfd.promise;
			},

			handleClose(modal) {
				this.$refs[modal.id][0].handleClose();
			},

			onResult(modal, result) {
				modal.dialog = false;
				modal.dfd.resolve(result);
				this.removeModal(modal);
			},

			onError(modal, error) {
				modal.dialog = false;
				modal.dfd.reject(error);
				this.removeModal(modal);
			},

			removeModal(modal,goBack=true) {
				if(goBack){
					this.ignoreBackEvent = true;
					window.history.back();
				}

				let ix = this.modals.findIndex((m) => m.id === modal.id);
				this.modals.splice(ix, 1);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
