<template>
  <div style="width:100%; height:100%; min-height: 100%">
    <form-v1 v-if="schemaVersion === 1" :form="form" :font="font" :in-frame="false" :complete="isComplete" ref="form" @complete="saveForm($event)"></form-v1>
    <form-v2 v-if="schemaVersion === 2" :form="form" :font="font" :in-frame="false" :complete="isComplete" ref="form" @complete="saveForm($event)"></form-v2>
  </div>
</template>

<script>
	import qs from 'qs';
	import HttpClient from '../../services/HttpClient';
	import WebFontLoader from 'webfontloader';
  import FormV1 from '@/modules/forms/v1/FormV1';
  import FormV2 from '@/modules/forms/v2/FormV2';

	export default {
		name: 'DiscoveryForm',

		components: { FormV1, FormV2 },

		data() {
			return {
				httpClient: null,
				form: null,
        schemaVersion: 0,
				result: null,
				token: null,
				isComplete: false,
				notFound: false,
				isSending: false,
				font: null
			};
		},

		mounted() {
      this.$vuetify.theme.dark = false;
      setTimeout(() => this.$vuetify.theme.dark = false,50);

			let queryString = qs.parse(self.location.search.slice(1));
			let request = JSON.parse(atob(queryString.token.split('.')[0]));

			let podUrl = request.podUrl;

			this.httpClient = new HttpClient(podUrl, false);
			this.token = queryString.token;

			this.httpClient
				.get('/api/docs/discovery', {
					params: {
						token: this.token,
					},
				})
				.then((res) => {
					this.form = res.data;
          this.schemaVersion = this.form.schemaVersion;
					WebFontLoader.load({
						google: {
							families: [`${this.form.font}:200,300,400,500,600`],
						},
						active: this.setFont,
					});
				})
				.catch((err) => {
					this.notFound = true;
					console.log(err.response.data);
				});
		},

		methods: {
			handleResult(result) {
				this.result = result;
			},

			setFont: function() {
				this.font = this.form.font;
			},

			saveForm(result) {
        this.$store.commit('startLoading');
        let formResult = result.formResult;
        let files = result.files;

				let response = {
					token: this.token,
					formData: formResult,
				};

				let formData = new FormData();
				formData.append('response', JSON.stringify(response));
				files.forEach((a) => {
					formData.append('files', a);
				});

				this.httpClient
					.post('/api/docs/discovery', formData, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					})
					.then(() => {
						this.isComplete = true;

            try {
              localStorage.removeItem(`${this.form.accountId}.${this.form.uniqueTemplateId}`);
            }catch(err){
              console.log(err);
            }
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.status + ' - ' + err.response.data.message);
						console.log(err.response.data);
					})
					.finally(() => {
						this.$store.commit('stopLoading');
					});
			},
		},
	};
</script>

<style scoped>

</style>
