<template>
  <div style="width:100%; height: 100%; background-color: #fff">
    <iframe v-if="podId" :src="fullPath" height="99%" width="100%" style="border: none; background-color: #fff"></iframe>
  </div>
</template>

<script>
	export default {
		name: 'PublicRouter',

		props: ['accountKey', 'resourceId'],

		components: {},

		data: function() {
			return {};
		},

		mounted() {
      window.addEventListener("message", (event) => {
        if(event.data && event.data.startsWith('[Redirect]')){
          let url = event.data.slice(10);
          window.location = url;
        }
      }, false);
    },

		beforeDestroy() {},

		methods: {},

		computed: {
			fullPath: function() {
        let url = new URL(`${process.env.VUE_APP_MEET_LINK}${this.podId}/${this.accountKey}/${this.resourceId}`);
        url.searchParams.append("inPortal", "true");
        if(this.opportunityId){
          url.searchParams.append("opportunityId",this.opportunityId);
        }
        if(this.action){
          url.searchParams.append("action",this.action);
        }
        if(this.id){
          url.searchParams.append("id",this.id);
        }
        return url.href;
			},

      opportunityId: function () {
        return this.$route.query.opportunityId;
      },

      action: function(){
        return this.$route.query.action;
      },

      id: function(){
        return this.$route.query.id;
      },

			podId: function() {
				if (this.$store.state.domain) {
					let domain = new URL(this.$store.state.domain.podUrl).hostname;
					return domain.split('.')[0].replace(/\D/g, '');
				} else {
					return null;
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
