<template>
	<div class="text-left">
		<div class="row-format align-center gap-3">
			<div>
				<div class="description">
					<div style="white-space: pre-wrap">{{ item.description }}</div>
				</div>
				<div class="font-14" v-if="tokens.get('Item.DueDate')">{{ tokens.get('Item.DueDate') }}</div>
			</div>
			<div class="ml-auto text-right">
				<span style="font-weight: 600">{{ tokens.get('Item.Total') }}</span
				><br />
				<span v-if="item.taxable" class="font-14">+{{ tokens.get('Item.TaxRate') }} {{ tokens.get('Item.TaxLabel') }}</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ServiceItemRenderer',

		props: ['item', 'tokens'],

		components: {},

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.description {
		font-weight: 400;
		div::first-line {
			font-weight: 600;
		}
	}
</style>
