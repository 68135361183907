<template>
	<div>
    <custom-page v-if="customPage" :slug="customPage.slug"></custom-page>
		<div
      v-else
			id="welcome-panel"
			:style="`height:100%; width:100%; --background:url('${domain.customBranding.loginSplash}')`"
			class="column-format centered"
		>
			<div class="content-panel column-format centered">
				<div style="max-width: 600px">
					<div class="mt-8" v-html="domain.customBranding.welcomeGreeting"></div>
				</div>
				<div class="row-format mt-8 px-8" style="flex-wrap: wrap" v-if="actionItems">
					<deliverables
						class="action-item"
						v-if="features.projectsEnabled"
						:deliverables="actionItems.deliverables"
						@refresh="getActionItems"
					></deliverables>
					<invoices
						class="action-item"
						v-if="features.invoicesEnabled"
						:invoices="actionItems.invoices"
						@refresh="getActionItems"
					></invoices>
					<proposals
						class="action-item"
						v-if="features.proposalsEnabled"
						:proposals="actionItems.proposals"
						@refresh="getActionItems"
					></proposals>
					<meetings
						class="action-item"
						v-if="features.meetingsEnabled"
						:meetings="actionItems.meetings"
						@refresh="getActionItems"
					></meetings>
					<subscription-requests
						class="action-item"
						v-if="features.invoicesEnabled"
						:subscription-requests="actionItems.subscriptionRequests"
						@refresh="getActionItems"
					></subscription-requests>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import DashboardService from '@/modules/welcome/DashboardService';
	import Deliverables from '@/modules/welcome/actionItems/Deliverables';
	import Invoices from '@/modules/welcome/actionItems/Invoices';
	import Meetings from '@/modules/welcome/actionItems/Meetings';
	import Proposals from '@/modules/welcome/actionItems/Proposals';
	import SubscriptionRequests from '@/modules/welcome/actionItems/SubscriptionRequests';
  import CustomPage from '@/modules/custom/CustomPage';

	export default {
		name: 'WelcomePage',

		props: [],

		components: { CustomPage, SubscriptionRequests, Deliverables, Invoices, Meetings, Proposals },

		data: function() {
			return {
				actionItems: null,
				dashboardService: new DashboardService(),
			};
		},

		mounted() {
			this.getActionItems();
		},

		beforeDestroy() {},

		methods: {
			getActionItems: function() {
				this.dashboardService.getActionItems().then((res) => {
					this.actionItems = res.data;
				});
			},

			logout: function() {
				this.$router.push('/logout');
			},
		},

		computed: {
      customPage: function(){
        return this.$store.state.customPages.find(p => p.isDefault);
      },

			domain: function() {
				return this.$store.state.domain;
			},

			features: function() {
				return this.$store.getters.getFeatures;
			},
		},
	};
</script>

<style lang="scss">
	.action-item {
		display: flex;
		flex-direction: column;
		min-width: 250px;
		flex: 1 1 0px;
		text-align: left;
		background-color: var(--v-white-base);
		border: 1px solid var(--v-gray_50-base);
		border-radius: 4px;
		box-sizing: border-box;
		box-shadow: 0px 1px 0px var(--v-gray_50-base);
		padding: 8px;
		margin: 0px 8px 8px 0px;
		&:hover {
			border: 1px solid var(--v-gray_90-base);
		}
	}

	.content-panel {
		background-color: var(--v-sidebar-base);
		border: 1px solid var(--v-gray_50-base);
		border-radius: 4px;
		box-sizing: border-box;
		padding: 24px;
		margin: 24px;
		opacity: 0.95;
	}

	#welcome-panel {
		background-image: var(--background);
		background-size: cover;
		min-height: 100vh;
		background-position: center;
		border-radius: 16px 16px 0px 0px;
	}
</style>