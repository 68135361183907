export default {
	methods: {
		sortPages: function(a, b) {
			let result = a.sortOrder - b.sortOrder;
			if (result == 0) {
				return a.title.localeCompare(b.title);
			} else {
				return result;
			}
		},
	},

	computed: {
		navLinks: function() {
			let standardPages = [];
			let customPages = [];
			let result = [];

			if (this.features.invoicesEnabled) {
				let page = {
					label: this.$t('nav.invoices'),
					icon: 'request_quote',
					route: '/invoices?v=list',
					tabs: [
						{
							label: this.$t('invoices.list.heading'),
							route: '/invoices?v=list',
						},
						{
							label: this.$t('invoices.reports.heading'),
							route: '/invoices?v=reports',
						},
						{
							label: this.$t('invoices.ach-account.heading'),
							route: '/invoices?v=accounts',
						},
						{
							label: this.$t('subscriptions.heading'),
							route: '/invoices?v=subscriptions',
						},
					],
				}

				if(!this.features.summaryFinancialsEnabled){
					let ix = page.tabs.findIndex(t => t.route === '/invoices?v=reports');
					page.tabs.splice(ix,1);
				}

				standardPages.push(page);
			}


			if (this.features.timeEnabled) {
				standardPages.push({
					label: this.$t('nav.timetrack'),
					icon: 'timer',
					route: '/time?v=list',
					tabs: [
						{
							label: this.$t('timetrack.time-entries.heading'),
							route: '/time?v=list',
						},
						{
							label: this.$t('timetrack.reports.heading'),
							route: '/time?v=reports',
						},
					],
				});
			}

			if (this.features.projectsEnabled) {
				standardPages.push({
					label: this.$t('nav.projects'),
					icon: 'folder_special',
					route: '/projects?v=overview',
					tabs: [
						{
							label: this.$t('projects.overview.heading'),
							route: '/projects?v=overview',
						},
						{
							label: this.$t('projects.kanban'),
							route: '/projects?v=kanban',
						},
						{
							label: this.$t('projects.timeline'),
							route: '/projects?v=timeline',
						},
					],
				});
			}

			if (this.features.proposalsEnabled) {
				standardPages.push({ label: this.$t('nav.agreements'), icon: 'handshake', route: '/agreements' });
			}

			if (this.features.meetingsEnabled) {
				standardPages.push({
					label: this.$t('nav.meetings'),
					icon: 'group',
					route: '/meetings/schedulers',
					tabs: [
						{
							label: this.$t('meetings.schedule.heading'),
							route: '/meetings/schedulers',
						},
						{
							label: this.$t('meetings.booked.heading'),
							route: '/meetings/booked',
						},
					],
				});
			}

			if (this.features.ticketsEnabled) {
				standardPages.push({ label: this.$t('nav.requests'), icon: 'contact_support', route: '/requests' });
			}

			this.customPages.forEach((p) => {
				let page = {
					label: p.title,
					icon: p.icon,
					hyperLink: p.type === 'External' ? p.hyperLink : null,
					route: `/pages/${p.slug}`,
					sortOrder: p.sortOrder,
				};

				if(p.isDefault) {
					result.push(page);
				}else{
					customPages.push(page);
				}
			});

			let totalPages = customPages.length + standardPages.length;

			for (let i = 0; i <= totalPages; i++) {
				let pagePosition = i + 1;
				let ix = customPages.findIndex((p) => p.sortOrder === pagePosition);
				if (ix > -1) {
					let page = customPages.splice(ix, 1)[0];
					result.push(page);
				} else if (standardPages.length) {
					result.push(standardPages.shift());
				} else if (customPages.length) {
					result.push(customPages.shift());
				}
			}

			return result;
		},

		customPages: function() {
			let result = [... this.$store.state.customPages];
			result.sort(this.sortPages);
			return result;
		},

		clientInitials() {
			let ignoreMe = ['a', 'an', 'as', 'to', 'and', 'of', 'if', 'in', '-'];
			let accountName = this.$store.getters.getClientName;
			if (!accountName) {
				return '?';
			}
			let words = this.$store.getters.getClientName.toLowerCase().split(' ');
			let ret = [];
			for (let i = 0; i < words.length; i++) {
				if (ignoreMe.indexOf(words[i]) > -1) continue;
				ret[i] = words[i].substring(0, 1);
				if (ret.length == 3) break;
			}
			return ret.join('').toUpperCase();
		},

		logo: function() {
			if (this.$vuetify.theme.dark && this.$store.state.domain.logoDark) {
				return this.$store.state.domain.logoDark;
			} else {
				return this.$store.state.domain.logo;
			}
		},

		features: function() {
			return this.$store.getters.getFeatures;
		},
	},
};
