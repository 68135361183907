<template>
  <div class="fill-height column-format">
      <h-header :add-new-label="$t('requests.new-request')" @add-new="newTicket()">
      </h-header>
      <div class="show-scrollbar" style="height: var(--vh)">
        <div class="mx-3 mt-5">
          <v-data-table
              id="timer-events-table"
              :headers="headers"
              sort-by="updated"
              :sort-desc="true"
              :must-sort="true"
              :items="filteredTickets"
              :disable-pagination="true"
              :hide-default-footer="true"
              style="cursor: pointer"
              :mobile-breakpoint="200"
              :no-data-text="$t('requests.no-found-requests')"
              @click:row="viewTicketDetail($event)"
          >
            <template v-slot:item.status="{ item }">
              <div class="row-format align-center">
                <div v-if="item.unreadClient" class="umi mr-2"></div>
                <div v-if="item.open" class="status">{{ item.status }}</div>
                <div v-else class="closed">{{ $t('requests.closed') }}</div>
              </div>
            </template>

            <template v-slot:item.type="{ item }">
              <span v-if="getTicketType(item.type)">{{ getTicketType(item.type).type }}</span>
              <span v-else> -- </span>
            </template>

            <template v-slot:item.updated="{ item }">
              {{ DateTime.fromISO(item.updated).toLocaleString(DateTime.DATETIME_MED) }}
            </template>

            <template v-slot:item.subject="{ item }">
              {{ truncate(item.subject, 50) }}
            </template>

            <template v-slot:item.lastComment="{ item }">
              <div class="truncate-item" v-html="item.lastComment"></div>
            </template>
          </v-data-table>
          <div class="text-left font-14 font-primary pointer ml-2" style="width: fit-content"
               @click="toggleClosedTickets">
            {{ showClosedTickets ? $t('requests.list.hide') : $t('requests.list.show') }} {{ closedTicketCount }}
            {{ $t('requests.list.closed') }}
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import HHeader from '@/components/HHeader';
import TicketService from '@/modules/tickets/TicketService';
import {DateTime} from 'luxon';

export default {
  name: 'TicketList',

  props: [],

  components: {HHeader},

  data: function () {
    return {
      DateTime: DateTime,
      ticketService: new TicketService(),
      ticketTypes: [],
      tickets: [],
      showClosedTickets: false,
    };
  },

  mounted() {
    this.initialize();
    this.setupTicketListeners();
  },

  beforeDestroy() {
    this.stopTicketListeners();
  },

  methods: {
    setupTicketListeners: function () {
      this.$store.state.eventBus.$on(
          this.$store.getters.getMessageChannelPrefix + '.ticketCreated',
          this.ticketNotification
      );
      this.$store.state.eventBus.$on(
          this.$store.getters.getMessageChannelPrefix + '.ticketUpdated',
          this.ticketNotification
      );
      this.$store.state.eventBus.$on(
          this.$store.getters.getMessageChannelPrefix + '.ticketComment',
          this.ticketNotification
      );
      this.$store.state.eventBus.$on(
          this.$store.getters.getMessageChannelPrefix + '.ticketDeleted',
          this.ticketNotification
      );
    },

    stopTicketListeners: function () {
      this.$store.state.eventBus.$off(
          this.$store.getters.getMessageChannelPrefix + '.ticketCreated',
          this.ticketNotification
      );
      this.$store.state.eventBus.$off(
          this.$store.getters.getMessageChannelPrefix + '.ticketUpdated',
          this.ticketNotification
      );
      this.$store.state.eventBus.$off(
          this.$store.getters.getMessageChannelPrefix + '.ticketComment',
          this.ticketNotification
      );
      this.$store.state.eventBus.$off(
          this.$store.getters.getMessageChannelPrefix + '.ticketDeleted',
          this.ticketNotification
      );
    },

    toggleClosedTickets: function () {
      this.showClosedTickets = !this.showClosedTickets;
    },

    ticketNotification: function (event) {
      if (event.channel.includes('ticketUpdated')) {
        let ticket = event.message;
        let ix = this.tickets.findIndex((t) => t.id === ticket.id);
        if (ix > -1) {
          this.tickets.splice(ix, 1, ticket);
        }
      } else if (event.channel.includes('ticketComment')) {
        let ticket = event.message.ticket;
        let ix = this.tickets.findIndex((t) => t.id === ticket.id);
        if (ix > -1) {
          this.tickets.splice(ix, 1, ticket);
        }
      } else if (event.channel.includes('ticketCreated')) {
        this.tickets.push(event.message.ticket);
      } else if (event.channel.includes('ticketDeleted')) {
        let ticketId = event.message;
        let ix = this.tickets.findIndex((t) => t.id === ticketId);
        if (ix > -1) {
          this.tickets.splice(ix, 1);
        }
      }
    },

    initialize: function () {
      this.getTickets();
      this.getTicketTypes();
    },

    viewTicketDetail: function (ticket) {
      this.$router.push(`/requests/${ticket.id}`);
    },

    getTicketTypes: function () {
      this.ticketService.getTicketTypes().then((res) => {
        this.ticketTypes.splice(0, this.ticketTypes.length);
        this.ticketTypes.push(...res.data);
      });
    },

    getTickets: function () {
      this.ticketService.getTicketList().then((res) => {
        this.tickets.splice(0, this.tickets.length);
        this.tickets.push(...res.data);
      });
    },

    truncate: function (string, length, end = '...') {
      if (string) {
        return string.length <= length ? string : string.substring(0, length) + end;
      } else {
        return '';
      }
    },

    getTicketType: function (id) {
      if (this.ticketTypes) {
        return this.ticketTypes.find((t) => t.id === id);
      } else {
        return {};
      }
    },

    newTicket: function () {
      this.$router.push('/requests/new');
    },
  },

  computed: {
    headers: function () {
      return [
        {text: '#', value: 'ticketNumber'},
        {text: this.$t('requests.list.status'), value: 'status'},
        {text: this.$t('requests.list.type'), value: 'type'},
        {text: this.$t('requests.list.subject'), value: 'subject'},
        {text: this.$t('requests.list.updated'), value: 'updated'},
        {text: this.$t('requests.list.last-comment'), value: 'lastComment'},
      ];
    },

    closedTicketCount: function () {
      return this.tickets.filter((t) => !t.open).length;
    },

    filteredTickets: function () {
      if (this.showClosedTickets) {
        return this.tickets;
      } else {
        return this.tickets.filter((t) => t.open);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.umi {
  width: 10px;
  height: 10px;
  background-color: var(--v-primary-base);
  border-radius: 10px;
}

.closed {
  background-color: var(--v-error-base);
  border-radius: 4px;
  padding: 0px 4px;
  color: var(--v-white-base);
  font-size: 12px;
  font-weight: 500;
}

.status {
  background-color: var(--v-accent-base);
  border-radius: 4px;
  padding: 0px 4px;
  color: var(--v-black-base);
  font-size: 12px;
  font-weight: 500;
}

.truncate-item {
  width: 100%; /* Adjust the width as needed */
  max-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

</style>
