<template>
	<div class="fill-height column-format">
		<h-header>
			<template v-slot:filter>
				<div class="row-format" style="flex-wrap: wrap">
					<v-text-field
						v-if="view === 'list'"
						dense
						hide-details
						prepend-icon="$filter"
						:placeholder="$t('global.filter')"
						style="font-size:14px; width:200px;"
						v-model="filter"
						clearable
					></v-text-field>
					<v-select
						v-if="view === 'list' || view === 'reports'"
						clearable
						dense
						hide-details
						v-model="dateFilter"
						:items="dateOptions"
						:placeholder="$t('global.date')"
						class="ml-3"
						style="width:200px"
					></v-select>
				</div>
			</template>
		</h-header>

    <div class="show-scrollbar" style="height: var(--vh)">
      <invoice-table
        :invoices="dateFilteredInvoices"
        v-show="view === 'list'"
        ref="InvoiceTable"
        :filter="filter"
        @refresh="getInvoices"
      ></invoice-table>

      <invoice-charts
        :invoices="dateFilteredInvoices"
        :projects="projects"
        :deposit-account="depositAccount"
        v-show="view === 'reports' && $store.getters.getFeatures.summaryFinancialsEnabled"
        ref="InvoiceChart"
      ></invoice-charts>

      <payment-methods :ach-enabled="achEnabled" v-show="view === 'accounts'"></payment-methods>
      <subscription-list v-if="view === 'subscriptions'"></subscription-list>
    </div>
	</div>
</template>

<script>
	import HHeader from '@/components/HHeader';
	import InvoiceTable from '@/modules/invoices/InvoiceTable';
	import InvoiceService from '@/modules/invoices/InvoiceService';
	import InvoiceCharts from '@/modules/invoices/InvoiceCharts';
	import { DateTime } from 'luxon';
	import ProjectService from '@/modules/projects/ProjectService';
	import FilterHelpers from '@/utils/FilterHelpers';
	import PaymentMethods from '@/modules/invoices/PaymentMethods';
  import SubscriptionList from '@/modules/subscriptions/SubscriptionList';
  import SubscriptionService from '@/modules/subscriptions/SubscriptionService';

	export default {
		name: 'InvoiceList',

		components: { SubscriptionList, InvoiceCharts, InvoiceTable, HHeader, PaymentMethods },

		props: ['v'],

		mixins: [],

		data: function() {
			return {
				filter: null,
				dateFilter: null,
				earliest: null,
				latest: null,
				view: 'list',
				achEnabled: false,
        subscriptionsEnabled: false,
				invoices: [],
				projects: [],
				depositAccount: null,
				invoiceService: new InvoiceService(),
				projectService: new ProjectService(),
        subscriptionService: new SubscriptionService(),

				dateOptions: FilterHelpers.getDateOptions([
					'this-month',
					'last-month',
					'past-60-days',
					'past-90-days',
					'past-6-months',
					'past-12-months',
					'this-year',
					'last-year',
				]),
			};
		},

		mounted() {
			if (this.v) {
				this.view = this.v;
			}

			this.getInvoices();
			this.getProjects();
			this.getDeposits();
      this.checkAchEnabled();
      this.checkSubscriptionEnabled();
		},

		beforeDestroy() {},

		methods: {
      setView: function(view) {
				this.view = view;
				window.history.pushState({}, document.title, this.$router.currentRoute.path + `?v=${view}`);
			},

      checkAchEnabled: function(){
        this.invoiceService.getAchEnabled().then((res) => this.achEnabled = res.data);
      },

      checkSubscriptionEnabled: function(){
        this.subscriptionService.checkSubscriptionsEnabled().then((res) => this.subscriptionsEnabled = res.data);
      },

			getInvoices: function() {
				this.invoiceService.getInvoices().then((res) => {
					this.invoices.splice(0, this.invoices.length);
					this.invoices.push(...res.data);
					this.invoices.forEach((i) => (i.statusDetail = this.getStatus(i)));
					this.isReady = true;
				});
			},

			getDeposits: function() {
				this.invoiceService.getDepositAccount().then((res) => (this.depositAccount = res.data));
			},

			getProjects: function() {
				this.projectService.getProjects().then((res) => {
					this.projects.splice(0, this.projects.length);
					this.projects.push(...res.data);
				});
			},

			getStatus(invoice) {
				let background, color, label;
				let payable = true;

				switch (invoice.status) {
					case 'PAID': {
						background = '--v-success_10-base';
						color = '--v-success-base';
						label = this.$t('invoices.status.paid');
						payable = false;
						break;
					}

					case 'PARTIAL':
					case 'SENT': {
						if (DateTime.fromISO(invoice.dateDue) < DateTime.now()) {
							background = '--v-alert_10-base';
							color = '--v-alert-base';
							label = this.$t('invoices.status.late');
						} else if (invoice.status === 'PARTIAL') {
							background = '--v-warning_10-base';
							color = '--v-warning-base';
							label = this.$t('invoices.status.partially-paid');
						} else {
							background = '--v-blue_5-base';
							color = '--v-gray_80-base';
							label = this.$t('invoices.status.pending');
						}

						break;
					}

					default: {
						background = '--v-gray_20-base';
						color = '--v-gray_80-base';
						label = invoice.status.charAt(0).toUpperCase() + invoice.status.toLowerCase().slice(1);
						payable = false;
						break;
					}
				}

				return {
					background,
					color,
					label,
					payable,
				};
			},
		},

		watch: {
			dateFilter: function(val) {
				if (val) {
					let helper = FilterHelpers.getEarliestAndLatest(val, false);
					this.earliest = helper.earliest;
					this.latest = helper.latest;
				} else {
					this.earliest = null;
					this.latest = null;
				}
			},

      v: function(val){
        this.setView(val);
      }
		},

		computed: {
			pages: function() {
				let result = [
					{ label: this.$t('invoices.list.heading'), value: 'list' },
					{ label: this.$t('invoices.reports.heading'), value: 'reports' }
				];

        if(this.achEnabled){
          result.push({ label: this.$t('invoices.ach-account.heading'), value: 'accounts' })
        }

        if(this.subscriptionsEnabled){
          result.push({ label: this.$t('subscriptions.heading'), value: 'subscriptions' })
        }

        return result;
			},

			dateFilteredInvoices: function() {
				if (this.earliest && this.latest) {
					return this.invoices.filter((i) => {
						let dateSent = DateTime.fromISO(i.dateSent);
						if (dateSent >= this.earliest && dateSent <= this.latest) {
							return true;
						} else {
							return false;
						}
					});
				} else {
					return this.invoices;
				}
			},
		},
	};
</script>

<style scoped lang="scss">

</style>
