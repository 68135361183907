import { render, staticRenderFns } from "./InvoiceMain.vue?vue&type=template&id=98c8e1b8&scoped=true"
import script from "./InvoiceMain.vue?vue&type=script&lang=js"
export * from "./InvoiceMain.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98c8e1b8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VSelect,VTextField})
