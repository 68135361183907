<template>
	<div class="column-format font-14 ma-3" v-if="actionItems">
		<deliverables
			class="action-item"
			v-if="features.projectsEnabled"
			:deliverables="actionItems.deliverables"
			@refresh="getActionItems"
		></deliverables>
		<invoices
			class="action-item"
			v-if="features.invoicesEnabled"
			:invoices="actionItems.invoices"
			@refresh="getActionItems"
		></invoices>
		<proposals
			class="action-item"
			v-if="features.proposalsEnabled"
			:proposals="actionItems.proposals"
      :agreements="actionItems.agreements"
			@refresh="getActionItems"
		></proposals>
		<meetings
			class="action-item"
			v-if="features.meetingsEnabled"
			:meetings="actionItems.meetings"
			@refresh="getActionItems"
		></meetings>
		<subscription-requests
			class="action-item"
			v-if="features.invoicesEnabled"
			:subscription-requests="actionItems.subscriptionRequests"
			@refresh="getActionItems"
		></subscription-requests>
		<div v-if="actionCount === 0" style="text-align: center; width:100%">
			<div class="font-14 font-gray_70">Zero notifications found</div>
		</div>
	</div>
</template>

<script>
	import SubscriptionRequests from '@/modules/welcome/actionItems/SubscriptionRequests';
	import Deliverables from '@/modules/welcome/actionItems/Deliverables';
	import Invoices from '@/modules/welcome/actionItems/Invoices';
	import Meetings from '@/modules/welcome/actionItems/Meetings';
	import Proposals from '@/modules/welcome/actionItems/Proposals';
	import DashboardService from '@/modules/welcome/DashboardService';

	export default {
		name: 'Notifications',

		props: [],

		components: { SubscriptionRequests, Deliverables, Invoices, Meetings, Proposals },

		data: function() {
			return {
				actionItems: null,
				dashboardService: new DashboardService(),
			};
		},

		mounted() {
			this.getActionItems();
		},

		beforeDestroy() {},

		methods: {
			getActionItems: function() {
				this.dashboardService.getActionItems().then((res) => {
					this.actionItems = res.data;
				});
			},
		},

		watch: {
			actionCount: function(val) {
				this.$store.state.actionCount = val;
			},
		},

		computed: {
			domain: function() {
				return this.$store.state.domain;
			},

			features: function() {
				return this.$store.getters.getFeatures;
			},

			actionCount: function() {
				if (this.actionItems) {
					return (
						this.actionItems.deliverables.length +
						this.actionItems.invoices.length +
						this.actionItems.proposals.length +
						this.actionItems.meetings.length +
						this.actionItems.subscriptionRequests.length +
            this.actionItems.agreements.length
					);
				} else {
					return 0;
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.action-item {
		display: flex;
		flex-direction: column;
		width:100%;
		flex: 1 1 0px;
		text-align: left;
		background-color: var(--v-white-base);
		border: 1px solid var(--v-gray_30-base);
		border-radius: 4px;
		box-sizing: border-box;
		box-shadow: 0px 1px 0px var(--v-gray_30-base);
		padding: 12px;
		&:hover {
			border: 1px solid var(--v-gray_50-base);
		}
	}
</style>
