<template>
	<div class="ma-4">
		<div class="mt-4">
			<v-data-table
				id="timer-events-table"
				:headers="headers"
				sort-by="dateTime"
				:sort-desc="true"
				:must-sort="true"
				:items="fixedUpEvents"
				:disable-pagination="true"
				:hide-default-footer="true"
				style="cursor: pointer"
				:mobile-breakpoint="200"
			>
				<template v-slot:item.invoiced="{ item }">
					<div class="brand-semilight font-12" @click.stop="openInvoice(item.invoiceId)" v-html="getStatus(item)"></div>
				</template>

				<template v-slot:item.timerStart="{ item }">
					<div style="white-space: nowrap">
						{{ item.dateDisplay }}
					</div>
				</template>

				<template v-slot:item.timeWorkedSortable="{ item }">
					<div class='time-worked text-end' style="text-wrap: none">
						{{ item.timeWorked }}
					</div>
				</template>

				<template v-slot:item.projectName="{ item }">
					<div>{{ item.projectName | empty }}</div>
				</template>

				<template v-slot:item.deliverableName="{ item }">
					<div>
						{{ item.deliverableName | empty }}
					</div>
				</template>

				<template v-slot:item.notes="{ item }">
					<div>
						{{ item.notes | empty }}
					</div>
				</template>

				<template v-slot:no-data>
					{{ $t('timetrack.time-entries.no-timetrack') }}
				</template>

				<template slot="body.append">
					<tr>
						<td></td>
						<th class="total text-end"><span class="font-14">{{ this.$t('timetrack.time-entries.total') }}</span></th>
						<td><div class="time-worked text-end">{{timeWorkedTotal}}</div></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
				</template>
			</v-data-table>
		</div>
	</div>
</template>

<script>
	import { DateTime } from 'luxon';
  import InvoiceDetails from '@/modules/invoices/InvoiceDetails';

	export default {
		name: 'TimerEventTable',
		components: {},
		props: ['timerEvents','filter'],

		data() {
			return {
				DateTime: DateTime,
			};
		},

		mounted() {},

		methods: {
			getStatus(event) {
				let background, color, label;

				if (event.invoiceId) {
					background = '--v-success_10-base';
					color = '--v-success';
					label = 'Billed';
				} else {
					label = '--';
				}

				return `<div class="invoice-status" style="--color:var(${color});--background:var(${background})">${label}</div>`;
			},

      openInvoice: function(invoiceId) {
        if(!invoiceId){
          return;
        }

        let binding = {
          invoiceId: invoiceId,
        };
        this.$store.state.globalModalController
            .openModal(InvoiceDetails, binding, true, true)
            .then(() => this.getInvoices());
      },
		},

		filters: {
			empty: function(value) {
				if (!value) return '--';
				return value;
			},
		},

		computed: {
			fixedUpEvents: function() {
				let result = [...this.timerEvents];

				result.forEach((item) => {
					let timerStart = DateTime.fromISO(item.timerStart);
          let timerEnd = timerStart.plus({seconds: item.duration});
					item.dateDisplay = timerStart.toFormat('DD');
					item.duration = timerEnd.diff(timerStart, ['hours', 'minutes', 'seconds', 'milliseconds']);
					item.timeWorkedSortable = timerEnd.diff(timerStart,['seconds']).seconds;
					item.timeWorked = item.duration.toFormat('h:mm:ss'); // Table display
                    item.invoiced = item.invoiceId ? true : false;
				});

				if (!this.filter) {
					return result;
				}

				let search = this.filter.toLowerCase();

				return result.filter((item) => {
					if (item.projectName && item.projectName.toLowerCase().includes(search)) {
						return true;
					}else if (item.deliverableName && item.deliverableName.toLowerCase().includes(search)) {
						return true;
					}else if (item.notes && item.notes.toLowerCase().includes(search)) {
						return true;
					} else {
						return false;
					}
				});
			},

			timeWorkedTotal: function() {
				let seconds = this.fixedUpEvents.reduce((a, b) => a + (b.timeWorkedSortable || 0), 0);
				let starty = DateTime.fromSeconds(0);
				let endy = DateTime.fromSeconds(seconds);
				let duration = endy.diff(starty, ['hours','minutes','seconds']);
				return duration.toFormat('h:mm:ss');


				//
				// 	let hours = seconds/(60*60);
				// return Math.floor(hours) + ':' + DateTime.fromSeconds(seconds).toFormat('mm:ss');
			},

			headers: function() {
				return [
					{ text: this.$t('timetrack.time-entries.status'), value: 'invoiced' },
					{ text: this.$t('global.date'), value: 'timerStart' },
					{ text: this.$t('timetrack.time-entries.time-worked'), value: 'timeWorkedSortable' },
					{ text: this.$t('timetrack.time-entries.project'), value: 'projectName' },
					{ text: this.$t('timetrack.time-entries.deliverable'), value: 'deliverableName' },
					{ text: this.$t('timetrack.time-entries.notes'), value: 'notes' },
				];
			},
		},

		watch: {},
	};
</script>

<style lang="scss">
	.invoice-status {
		width: fit-content;
		padding: 4px 8px;
		flex: none;
		order: 0;
		flex-grow: 0;
		background-color: var(--background);
		color: var(--color);
		border-radius: 4px;
		white-space: nowrap;
	}
	#timer-events-table {
		.time-worked {
			max-width: 140px;
			//color: var(--v-gray_80-base);
		}
		.total {
			color: var(--v-gray_80-base);
		}
	}
</style>
