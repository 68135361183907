<template>
	<div class="fill-height column-format" v-if="isReady">
		<h-header>
			<template v-slot:filter>
				<div class="row-format" style="flex-wrap: wrap">
					<v-text-field
						v-if="view === 'list'"
						dense
						hide-details
						prepend-icon="$filter"
						:placeholder="$t('global.filter')"
						style="font-size:14px; width:200px"
						v-model="filter"
						clearable
						@change="handleFilterChange"
					></v-text-field>
					<v-select
						clearable
						dense
						hide-details
						v-model="dateFilter"
						:items="dateOptions"
						:placeholder="$t('global.date')"
						class="ml-3"
						style="width:200px"
						@change="handleDateFilterChange"
					></v-select>
				</div>
			</template>
		</h-header>
    <div class="show-scrollbar" style="height: var(--vh)">
      <timer-event-table v-show="view === 'list'" :timer-events="timerEvents" :filter="filter"></timer-event-table>
      <timer-event-charts v-show="view === 'reports'" :timer-events="timerEvents" :earliest="earliest" :latest="latest"></timer-event-charts>
    </div>
	</div>
</template>

<script>
	import HHeader from '@/components/HHeader';
	import FilterHelpers from '@/utils/FilterHelpers';
	import TimerEventService from '@/modules/timetracking/TimerEventService';
	import TimerEventTable from '@/modules/timetracking/TimerEventTable';
	import TimerEventCharts from '@/modules/timetracking/TimerEventCharts';
	import { DateTime } from 'luxon';

	export default {
		name: 'TimerEventList',

		components: { TimerEventCharts, TimerEventTable, HHeader },

    props: ['v'],

		mixins: [],

		data: function() {
			return {
				filter: null,
				dateFilter: null,
				earliest: null,
				latest: null,
				view: 'list',
                isReady: false,
				timerEvents: [],
				timerEventService: new TimerEventService(),

				dateOptions: FilterHelpers.getDateOptions([
					'this-month',
					'last-month',
					'last-last-month',
					'past-60-days',
					'past-90-days',
					'past-6-months',
					'past-12-months',
					'this-year',
					'last-year',
				]),

			};
		},

		mounted() {
      if (this.v) {
        this.view = this.v;
      }

			this.getDateFilter();
			this.init();
		},

		beforeDestroy() {},

		methods: {
      setView: function(view) {
        this.view = view;
        window.history.pushState({}, document.title, this.$router.currentRoute.path + `?v=${view}`);
      },

			init: function(){
				this.setEarliestAndLatest();
				this.getTimerEvents();
			},

			handleFilterChange(event) {
				this.setFilter(event);
				// this.init();
			},

			getFilter: function(){
				let filter = sessionStorage.getItem('TIMETRACK_FILTER');
				if(filter){
					this.filter = JSON.parse(filter);
				}else{
					this.setFilter();
				}
			},

			setFilter: function(filter){
				this.filter = filter;
				localStorage.setItem('TIMETRACK_FILTER',JSON.stringify(filter));
			},

			handleDateFilterChange(event) {
				this.setDateFilter(event);
				this.init();
			},

			getDateFilter: function(){
				let dateFilter = localStorage.getItem('TIMETRACK_DATE_FILTER');
				console.log('getFilter');
				if(dateFilter){
					console.log('hello');
					this.dateFilter = JSON.parse(dateFilter);
				}else{
					console.log('setting, yo');
					this.setDateFilter('past-60-days');
				}
			},

			setDateFilter: function(filter){
				this.dateFilter = filter;
				localStorage.setItem('TIMETRACK_DATE_FILTER',JSON.stringify(filter));
			},


			getTimerEvents: function() {
				this.timerEventService.getTimerEvents(this.earliest, this.latest)
					.then((res) => {
						this.timerEvents.splice(0, this.timerEvents.length);
						this.timerEvents.push(...res.data);
						this.timerEvents.forEach(item => {
							item.startDate = DateTime.fromISO(item.timerStart);
              item.endDate = item.startDate.plus({seconds: item.duration});
              item.durationSeconds = item.endDate.diff(item.startDate,['seconds']).seconds;
						});
						this.isReady = true;
					});
			},

			setEarliestAndLatest: function() {
				if (this.dateFilter) {
					let helper = FilterHelpers.getEarliestAndLatest(this.dateFilter, false);
					this.earliest = helper.earliest;
					this.latest = helper.latest;
				} else {
					this.earliest = null;
					this.latest = null;
				}
			},
		},

		watch: {
      v: function(val){
        this.setView(val);
      }
    },

		computed: {
      pages: function() {
        let result = [
              {label: this.$t('timetrack.time-entries.heading'), value: 'list'},
              {label: this.$t('timetrack.reports.heading'), value: 'reports'},
            ];
            return result;
      },
    },
	};
</script>

<style lang="scss">

</style>
